import { useTheme } from 'next-themes';

import Switch from 'ui/switch';

import Moon from './moon.svg';
import Sun from './sun.svg';
import css from './theme-toggle.module.scss';
import useWindowDimensions from '../../../application/hooks/use-window-dimensions';

const ThemeToggle = () => {
  const { theme, setTheme } = useTheme();
  const { mounted } = useWindowDimensions();

  if (!mounted) {
    return null;
  }

  const items = [
    { value: 'light', label: <Sun className={css.icon} /> },
    { value: 'dark', label: <Moon className={css.icon} /> },
  ];

  const currentTheme = theme ?? 'light';

  const handleChange = (newTheme: string) => {
    setTheme(newTheme);
  };

  return (
    <Switch
      value={currentTheme}
      items={items}
      onChange={handleChange}
      className={css.themeToggle}
      circleClassName={css.circleClassName}
    />
  );
};

export default ThemeToggle;
