import { useTranslation } from 'next-i18next';

import { useUser } from 'application/context/user-provider';
import webApp from 'application/web-app';

import GeneralLayout from './general-layout';

export interface GeneralLayoutConnectedProps {
  title?: string;
  description?: string;
  keywords?: string[];
  imgPreview?: string;
  footer?: boolean;
  children: React.ReactNode;
}

const GeneralLayoutConnected: React.FC<GeneralLayoutConnectedProps> = ({
  footer = true,
  children,
  ...meta
}) => {
  const { user, logoutUserContext } = useUser();
  const { t } = useTranslation(['footer', 'help']);

  const headerUrls = [{ label: t('footer:about_us'), url: '/about' }];

  const footerUrls = [
    { label: t('footer:about_us'), url: '/about' },
    { label: t('footer:articles'), url: '/articles' },
    { label: t('footer:rules'), url: '/rules' },
    {
      label: t('footer:prediction_contest_rules'),
      url: '/rules-and-conditions-of-the-prediction-contest',
    },
    { label: t('help:labels:help'), url: '/help' },
    { label: t('footer:vacancies'), url: '/job' },
    { label: t('footer:contact_us'), url: `mailto:${webApp.supportEmail}` },
  ];

  return (
    <GeneralLayout
      headerUrls={headerUrls}
      user={user}
      userLogout={logoutUserContext}
      footerUrls={footerUrls}
      footer={footer}
      {...meta}
    >
      {children}
    </GeneralLayout>
  );
};

export default GeneralLayoutConnected;
