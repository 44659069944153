import { useQuery } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { errorNotification } from 'application/notification';
import { getCorrectSortDirection } from 'features/cabinet/utils/getCorrectSortDirection';
import { Toast } from 'ui';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import GET_MY_PARI_INFO, {
  GetMyPariInfoQuery,
  GetMyPariInfoQueryVars,
} from '../graphql/get-my-pari-info';
import { ClientPariListTab, PariSortBy } from '../pari.types';

export const useClientPariList = () => {
  const { t } = useTranslation(['events']);

  const [sort, setSort] = useState<{
    key: PariSortBy;
    sort: SortDirection;
  }>({ key: 'date', sort: SortDirection.DESC });

  const [page, setPage] = useState(1);

  const [tab, setTab] = useState<ClientPariListTab>(ClientPariListTab.ACTIVE);

  const tabs = [
    {
      value: ClientPariListTab.ACTIVE,
      label: t('events:active'),
    },
    {
      value: ClientPariListTab.COMPLETED,
      label: t('events:completed_s'),
    },
    {
      value: ClientPariListTab.CANCELED,
      label: t('events:canceled'),
    },
  ];

  const { data, error, loading } = useQuery<
    GetMyPariInfoQuery,
    GetMyPariInfoQueryVars
  >(GET_MY_PARI_INFO, {
    variables: {
      sort: {
        sortDirection: getCorrectSortDirection(sort.sort),
        page,
        sortBy: sort.key,
        status: tab,
      },
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getMyPariInfo.__typename === 'Error') {
      errorNotification(data.getMyPariInfo.error);
    }
  }, [data, error]);

  return {
    data,
    loading,
    sort: { sort, setSort },
    pagination: { page, setPage },
    tab,
    setTab,
    tabs,
  };
};

export default useClientPariList;
