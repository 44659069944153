import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_MY_IQ_RATING, {
  GetMyIqRatingQuery,
  GetMyIqRatingQueryVars,
} from '../graphql/get-my-iq-rating';

export const useMyIqRating = () => {
  const { data, error, loading } = useQuery<
    GetMyIqRatingQuery,
    GetMyIqRatingQueryVars
  >(GET_MY_IQ_RATING, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getMyIqRating.__typename === 'Error') {
      Toast.error(data.getMyIqRating.error);
    }
  }, [data, error]);

  return {
    rating:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? data.getMyIqRating.rating.toString()
        : '',
    pnlRating:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? data.getMyIqRating.pnlRating.toString()
        : '',
    position:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? data.getMyIqRating.position
        : null,
    win:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? data.getMyIqRating.win
        : 0,
    lose:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? data.getMyIqRating.lose
        : 0,
    record:
      data?.getMyIqRating.__typename === 'GetMyIqRating'
        ? `${data.getMyIqRating.win}-${data.getMyIqRating.lose}`
        : '',
    loading,
  };
};
