import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo, useState } from 'react';

import { useAppConfig } from 'application/context/app-config-provider';
import { useUser } from 'application/context/user-provider';
import { getRegistrationDate } from 'application/utils/date';
import webApp from 'application/web-app';
import { useMyIqRating } from 'features/iq-rating/hooks/use-my-iq-rating';
import { UserEventsStatistic } from 'features/user-statistic-events';
import LiveOrdersList from 'features/user-statistic-live-orders';
import BalanceWallets from 'features/wallets/pages/balance-wallets';
import { useTransferMoneyHook } from 'hooks/use-transfer-money.hook';
import { Card, Tabs, Toast } from 'ui';
import Button from 'ui/button/button';
import { Challenges } from 'ui/challenges/challenges';
import { challengesMock } from 'ui/challenges/challenges.mock';
import { Flex } from 'ui/flex';
import { Grid } from 'ui/grid';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveFlex } from 'ui/responsive-flex/responsive-flex';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Headline } from 'ui/v2/typography/headline';
import { Text } from 'ui/v2/typography/text';
import { VerticalScrollWrapper } from 'ui/vertical-scroll-wrapper/vertical-scroll-wrapper';

import css from './balance-page.module.scss';
import BookmarksNewsList from '../../news/components/bookmarks-news-list';
import ModeratedNewsList from '../../news/components/moderated-news-list';
import UnpublishedNewsPreviewList from '../../news/components/unpublished-news-preview-list';
import useClientCounts from '../../news/hooks/use-clients-counts';
import { useGetMySocialRating } from '../../social-rating/hooks/use-get-my-social-rating';
import ClientPariList from '../components/client-pari-list';
import StatisticInfo from '../components/statistic-info';
import Transactions from '../components/transactions-history';
import TransferMoneyDialog from '../components/transfer-money-dialog';
import { TransferMoneyDialogErrors } from '../components/transfer-money-dialog/transfer-money-dialog';
import UploadClientAvatar from '../components/upload-client-avatar';

interface BalancePageProps {}

export const enum BalancePageTabType {
  PARI = 'pari',
  USER_NOTIFICATIONS = 'user-notifications',
  UNPUBLISHED_NEWS_LIST = 'unpublished-news-list',
  MODERATED_NEWS_LIST = 'moderated-news-list',
  BOOKMARKS_NEWS_LIST = 'bookmarks-news-list',
  TRANSACTIONS = 'transactions',
  CHALLENGES = 'challenges',
  LIVE_ORDERS = 'live-orders',
  LIVE_EVENTS = 'live-events',
}

const BalancePage: React.FC<BalancePageProps> = ({}) => {
  const router = useRouter();
  const { tab } = router.query;

  const { appConfig } = useAppConfig();
  const { user } = useUser();

  const { t } = useTranslation();
  const [balanceType, setBalanceType] = useState<'vusd' | 'mvusd'>('vusd');
  const [transferDialogOpen, setTransferDialogOpen] = useState(false);
  const [exchangeOpened, setExchangeOpened] = useState(false);
  const [formErrors, setFormErrors] = useState<TransferMoneyDialogErrors>({});
  const newsCounts = useClientCounts();

  const revenue = useMemo(() => Number(user?.vusdLiveRevenue || 0), [user]);

  const { transferMoney, loading } = useTransferMoneyHook();

  const { rating, pnlRating, lose, win, position, record } = useMyIqRating();
  const socialRating = useGetMySocialRating();

  const setTab = (tab: BalancePageTabType) => {
    router.replace({ pathname: router.pathname, query: { tab } }, undefined, {
      shallow: true,
    });
  };

  const moveToWallet = async (value: number) => {
    try {
      const { errors, data } = await transferMoney({
        variables: {
          input: {
            from: balanceType,
            to: balanceType === 'vusd' ? 'mvusd' : 'vusd',
            amount: value.toString(),
          },
        },
      });

      if (errors) {
        Toast.error(errors.map((item) => item.message).join(', '));
        return;
      }

      if (!data) {
        Toast.error(t('common:errors:undefined_error'));
        return;
      }

      if (data?.transferMoney.__typename === 'Error') {
        if (data.transferMoney.warnings) {
          return setFormErrors(
            data.transferMoney.warnings.reduce(
              (prev, cur) => ({
                ...prev,
                [cur.key]: cur.warning,
              }),
              {}
            )
          );
        }
        Toast.error(data.transferMoney.error);
        return;
      }

      Toast.info(t('bets:money_transferred'));
    } catch (e) {
      console.log(e);
    } finally {
      setExchangeOpened(false);
    }
  };

  useEffect(() => {
    if (!tab) {
      router.replace({ query: { tab: BalancePageTabType.PARI } }, undefined, {
        shallow: true,
      });
    }
  }, [tab, router]);

  if (!user) {
    return null;
  }

  const tabItems = [
    {
      value: BalancePageTabType.PARI,
      label: 'Pari',
    },
    {
      value: BalancePageTabType.LIVE_ORDERS,
      label: 'Live',
    },
    {
      value: BalancePageTabType.LIVE_EVENTS,
      label: t('events:events'),
    },
    {
      value: BalancePageTabType.BOOKMARKS_NEWS_LIST,
      label: `${t('news:labels:news_bookmarks')} ${
        newsCounts?.bookmarksNewsCount
      }`,
    },
    {
      value: BalancePageTabType.UNPUBLISHED_NEWS_LIST,
      label: `${t('account:labels:drafts')} ${newsCounts?.draftNewsCount}`,
    },
  ];

  if (user?.editor) {
    tabItems.push({
      value: BalancePageTabType.MODERATED_NEWS_LIST,
      label: `${t('news:labels:user_news')} ${newsCounts?.reviewingNewsCount}`,
    });
  }

  return (
    <ResponsiveGrid
      width="100%"
      maxWidth="980px"
      gap={GridGap.x3}
      mobile={{ gap: GridGap.x2 }}
      className={css.Root}
    >
      <Card className={css.Card}>
        <ResponsiveGrid gap={GridGap.x3} mobile={{ gap: GridGap.x2 }}>
          <ResponsiveGrid gap={GridGap.x3} mobile={{ gap: GridGap.x2 }}>
            <ResponsiveFlex
              justifyContent="space-between"
              flexDirection="column"
              gap={'19px'}
              className={css.TestGap}
              mobile={{ gap: '11px' }}
              alignItems="flex-start"
            >
              <Flex gap={GridGap.x0} flexDirection="column">
                <Flex alignItems="center" gap={GridGap.x1}>
                  <Headline type="large">{user.username}</Headline>
                </Flex>

                <Text type="tagline" color="grey-primary-7" noWrap>
                  {`${t('account:labels:on_site')} ${getRegistrationDate(
                    new Date(user.createdAt)
                  )}`}
                </Text>
              </Flex>
              {appConfig?.referralEnabled && (
                <Text
                  type="caption3"
                  color="blue-primary"
                  style={{ fontSize: '11px' }}
                  onClick={() => router.push(webApp.router.getAffiliate())}
                  className={css.Affiliate}
                >
                  {t('account:labels:affiliate_program')}
                </Text>
              )}
            </ResponsiveFlex>

            <Flex gap={GridGap.x2}>
              <UploadClientAvatar avatar={user?.avatar} size="L" />

              <Grid
                gap={GridGap.x0}
                alignItems="flex-start"
                height="fit-content"
              >
                <Flex gap={GridGap.x1} flexDirection="column">
                  <StatisticInfo
                    content={t('account:labels:IQ_rating_desc')}
                    align="top"
                  >
                    <Text type="caption" color="grey-primary-7">
                      {t('account:labels:IQ_rating')}:&nbsp;
                    </Text>
                    <Text type="caption" color="blue-primary" bold>
                      {rating}
                    </Text>
                  </StatisticInfo>
                  <StatisticInfo
                    content={t('account:labels:PNL_rating_desc')}
                    align="left"
                  >
                    <Text type="caption" color="grey-primary-7">
                      {t('account:labels:PNL_rating')}:&nbsp;
                    </Text>
                    <Text type="caption" color="blue-primary" bold>
                      {pnlRating}
                    </Text>
                  </StatisticInfo>
                  <StatisticInfo
                    content={t('account:labels:rating_position')}
                    align={'left'}
                  >
                    <Text type="caption" color="grey-primary-7">
                      {t('account:labels:position')}:&nbsp;
                    </Text>
                    <Text type="caption" color="blue-primary" bold>
                      {position !== null ? `№ ${position}` : '-'}
                    </Text>
                  </StatisticInfo>
                  <StatisticInfo
                    content={t('account:labels:win_loss_record')}
                    align={'left'}
                  >
                    <Text type="caption" color="grey-primary-7">
                      {t('account:labels:record')}:&nbsp;
                    </Text>
                    <Text type="caption" color="blue-primary" bold>
                      {record}
                    </Text>
                  </StatisticInfo>
                </Flex>
              </Grid>
            </Flex>
          </ResponsiveGrid>

          <ResponsiveGrid gap={GridGap.x3} mobile={{ gap: GridGap.x2 }}>
            <Flex
              justifyContent="space-between"
              alignItems="flex-start"
              flexDirection="column"
            >
              <Flex gap={GridGap.x2} alignItems="center">
                <Text type="body2" color="black-primary" bold>
                  {t('bets:balance')}: {user.balanceVusd} vts
                </Text>
                <Text type="body2" color="black-primary" bold>
                  {t('bets:snapshot')}: {user.snapshotVts} vts
                </Text>
              </Flex>
            </Flex>

            <ResponsiveFlex
              justifyContent="flex-start"
              alignItems="center"
              gap={GridGap.x3}
              mobile={{ justifyContent: 'center' }}
            >
              <Button
                size="large"
                onClick={() => setTransferDialogOpen(true)}
                disabled
                className={css.Button}
              >
                {t('bets:replenish')}
              </Button>
              <Button
                size="large"
                color="invert"
                className={css.Button}
                disabled
              >
                {t('bets:withdraw')}
              </Button>
            </ResponsiveFlex>

            <ResponsiveFlex>
              <BalanceWallets />
            </ResponsiveFlex>
          </ResponsiveGrid>

          <Text
            type="caption3"
            color="blue-primary"
            style={{ cursor: 'pointer' }}
            onClick={() => setTab(BalancePageTabType.TRANSACTIONS)}
            className={css.History}
          >
            {t('bets:operations_history')}
          </Text>

          <VerticalScrollWrapper>
            <Tabs
              value={tab as BalancePageTabType}
              items={tabItems}
              onSelect={setTab}
            />
          </VerticalScrollWrapper>
        </ResponsiveGrid>
      </Card>

      {tab === BalancePageTabType.CHALLENGES && (
        <Challenges challenges={challengesMock} />
      )}
      {tab === BalancePageTabType.PARI && <ClientPariList />}
      {tab === BalancePageTabType.LIVE_ORDERS && <LiveOrdersList />}
      {tab === BalancePageTabType.LIVE_EVENTS && <UserEventsStatistic />}
      {tab === BalancePageTabType.TRANSACTIONS && (
        <Transactions balanceType={balanceType} />
      )}
      {tab === BalancePageTabType.BOOKMARKS_NEWS_LIST && <BookmarksNewsList />}
      {tab === BalancePageTabType.UNPUBLISHED_NEWS_LIST && (
        <UnpublishedNewsPreviewList />
      )}
      {tab === BalancePageTabType.MODERATED_NEWS_LIST && <ModeratedNewsList />}
      <TransferMoneyDialog
        from={balanceType}
        isOpen={transferDialogOpen}
        onClose={() => setTransferDialogOpen(false)}
      />
    </ResponsiveGrid>
  );
};

export default BalancePage;
