import cx from 'classnames';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import { formatDayMonthTime } from 'application/utils/date';
import { StickyColumn } from 'features/sticky-column/sticky-column';
import { useMediaQuery } from 'hooks/use-media-query.hook';
import { Flex } from 'ui/flex';
import { GridGap } from 'ui/grid/grid.types';
import { ResponsiveGrid } from 'ui/responsive-grid/responsive-grid';
import { Space } from 'ui/space/space';
import { Text } from 'ui/v2/typography/text';

import css from './client-pari-list.module.scss';
import PlayTableIcon from './play-table-icon.svg';
import { MyPariModel } from '../../graphql/get-my-pari-info';
import { ClientPariListTab } from '../../pari.types';

interface Props {
  pariList: MyPariModel[];
  currentTab?: ClientPariListTab;
  columnsGrid: string;
  columnsGridTablet: string;
  columnsGridMobile: string;
}

const PariListTableContent = ({
  pariList,
  currentTab,
  columnsGrid,
  columnsGridTablet,
  columnsGridMobile,
}: Props) => {
  const { t } = useTranslation(['events']);

  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(
    null
  );

  const toggleAccordion = (index: number, pariesLength: number) => {
    if (pariesLength <= 1) return;
    setOpenAccordionIndex(openAccordionIndex === index ? null : index);
  };

  return (
    <>
      {pariList.map((item, index) => (
        <div
          key={index}
          onClick={() => toggleAccordion(index, item.paries.length)}
          style={{ cursor: item.paries.length > 1 ? 'pointer' : 'default' }}
        >
          <ResponsiveGrid
            className={cx(css.Table, css.Border_top, {
              [css.Gray_back]: index % 2 === 0,
            })}
            alignItems="center"
            gap={GridGap.x2}
            gridTemplateColumns={columnsGrid}
            gridAutoRows="minmax(74px, 1fr)"
            tablet={{ gridTemplateColumns: columnsGridTablet }}
            mobile={{ gridTemplateColumns: columnsGridMobile }}
          >
            <Text
              type="caption"
              color={'black-primary'}
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
            >
              <span style={{ whiteSpace: 'wrap' }}>
                {currentTab !== ClientPariListTab.CANCELED &&
                  formatDayMonthTime(new Date(item.finishedAt))}
                {currentTab === ClientPariListTab.CANCELED &&
                  formatDayMonthTime(new Date(item.date))}
              </span>
            </Text>
            <ResponsiveGrid
              gridTemplateColumns="34px 1fr"
              alignItems="center"
              gap={GridGap.x2}
              className={css.Event}
              mobile={{ gap: GridGap.x1 }}
            >
              <img
                src={item.tournament.icon}
                width={34}
                height={34}
                alt={item.tournament.name}
              />
              <Flex flexDirection="column" style={{ whiteSpace: 'wrap' }}>
                <Link
                  href={`/tournament/${item.tournament.id}`}
                  style={{ fontSize: '15px' }}
                >
                  {`${item.result.name} - ${item.name}`}
                </Link>
                <Space size={4} />
                <Text
                  type="caption3"
                  color={'grey-primary-7'}
                  style={{ whiteSpace: 'wrap' }}
                >
                  {item.tournament.name}: {item.event?.name}
                </Text>
              </Flex>
            </ResponsiveGrid>
            <Text
              type="paragraph"
              style={{
                fontSize: '15px',
                textAlign: 'start',
                display: 'flex',
                alignItems: 'center',
              }}
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
            >
              <div className={css.WinAmountWrapper}>
                {item.paries.length > 1 && (
                  <span
                    className={cx(css.Icon, {
                      [css.ArrowIconOpen]: openAccordionIndex === index,
                    })}
                  >
                    <PlayTableIcon />
                  </span>
                )}
                <span className={css.WinAmount}>{item.amount.toFixed(2)}</span>
              </div>
            </Text>
            <Text
              type="paragraph"
              style={{ fontSize: '15px' }}
              mobile={{ fontSize: '12px' }}
              tablet={{ fontSize: '14px' }}
              textAlign="center"
              color={item.win === false ? 'black-primary' : 'blue-primary'}
            >
              {item.coeff.toFixed(2)}
            </Text>
            {currentTab === ClientPariListTab.ACTIVE ? (
              <StickyColumn>
                <Text
                  type="paragraph"
                  style={{ fontSize: '15px' }}
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  textAlign="center"
                >
                  {item.winAmount === 0 ? '-' : item.winAmount.toFixed(2)}
                </Text>
              </StickyColumn>
            ) : (
              <>
                <Text
                  type="paragraph"
                  style={{ fontSize: '15px' }}
                  mobile={{ fontSize: '12px' }}
                  tablet={{ fontSize: '14px' }}
                  textAlign="center"
                >
                  {item.winAmount === 0 ||
                  currentTab === ClientPariListTab.CANCELED
                    ? '-'
                    : item.winAmount.toFixed(2)}
                </Text>
                <StickyColumn className={css.NoPaddingStickyColumn}>
                  <Text
                    type="paragraph"
                    style={{ fontSize: '15px' }}
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                    textAlign="center"
                    color={
                      currentTab === ClientPariListTab.CANCELED
                        ? 'var(--font-color-primary)'
                        : item.win
                        ? 'green-primary'
                        : 'red-primary'
                    }
                  >
                    {currentTab === ClientPariListTab.CANCELED
                      ? t('events:status_returned')
                      : item.win
                      ? `+${Number(item.pnl).toFixed(2)}`
                      : Number(item.pnl).toFixed(2)}
                  </Text>
                </StickyColumn>
              </>
            )}
          </ResponsiveGrid>

          {/* Аккордеон для подробной информации */}
          {openAccordionIndex === index && (
            <div className={css.AccordionContent}>
              {item.paries.map((parie, parieIndex) => (
                <ResponsiveGrid
                  className={cx(css.Table, css.Border_top, {
                    [css.Gray_back]: index % 2 === 0,
                  })}
                  key={parieIndex}
                  alignItems="center"
                  gap={GridGap.x2}
                  gridTemplateColumns={columnsGrid}
                  gridAutoRows="minmax(74px, 1fr)"
                  tablet={{ gridTemplateColumns: columnsGridTablet }}
                  mobile={{ gridTemplateColumns: columnsGridMobile }}
                >
                  <Text
                    type="caption"
                    color="black-primary"
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                  >
                    <span style={{ whiteSpace: 'wrap' }}>
                      {currentTab !== ClientPariListTab.CANCELED &&
                        formatDayMonthTime(new Date(item.finishedAt))}
                      {currentTab === ClientPariListTab.CANCELED &&
                        formatDayMonthTime(new Date(item.date))}
                    </span>
                  </Text>
                  <ResponsiveGrid
                    gridTemplateColumns="34px 1fr"
                    alignItems="center"
                    gap={GridGap.x2}
                    className={css.Event}
                    mobile={{ gap: GridGap.x1 }}
                  >
                    <img
                      src={item.tournament.icon}
                      width={34}
                      height={34}
                      alt={item.tournament.name}
                    />
                    <Flex flexDirection="column">
                      <Link
                        href={`/tournament/${item.tournament.id}`}
                        style={{ fontSize: '15px' }}
                      >
                        {`${item.result.name} - ${item.name}`}
                      </Link>
                      <Space size={4} />
                      <Text
                        type="caption3"
                        color="grey-primary-7"
                        style={{ whiteSpace: 'wrap' }}
                      >
                        {item.tournament.name}: {item.event?.name}
                      </Text>
                    </Flex>
                  </ResponsiveGrid>
                  <Text
                    type="paragraph"
                    style={{ fontSize: '15px', textAlign: 'start' }}
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                    textAlign="center"
                  >
                    {parie.amount.toFixed(2)}
                  </Text>
                  <Text
                    type="paragraph"
                    style={{ fontSize: '15px' }}
                    mobile={{ fontSize: '12px' }}
                    tablet={{ fontSize: '14px' }}
                    textAlign="center"
                  >
                    {parie.coeff.toFixed(2)}
                  </Text>
                  {currentTab === ClientPariListTab.ACTIVE ? (
                    <StickyColumn>
                      <Text
                        type="paragraph"
                        style={{ fontSize: '15px' }}
                        mobile={{ fontSize: '12px' }}
                        tablet={{ fontSize: '14px' }}
                        textAlign="center"
                      >
                        {parie.winAmount === 0
                          ? '-'
                          : parie.winAmount.toFixed(2)}
                      </Text>
                    </StickyColumn>
                  ) : (
                    <>
                      <Text
                        type="paragraph"
                        style={{ fontSize: '15px' }}
                        mobile={{ fontSize: '12px' }}
                        tablet={{ fontSize: '14px' }}
                        textAlign="center"
                      >
                        {parie.winAmount === 0 ||
                        currentTab === ClientPariListTab.CANCELED
                          ? '-'
                          : parie.winAmount.toFixed(2)}
                      </Text>
                      <StickyColumn className={css.NoPaddingStickyColumn}>
                        <Text
                          type="paragraph"
                          style={{ fontSize: '15px' }}
                          mobile={{ fontSize: '12px' }}
                          tablet={{ fontSize: '14px' }}
                          textAlign="center"
                          color={
                            currentTab === ClientPariListTab.CANCELED
                              ? 'var(--font-color-primary)'
                              : item.win
                              ? 'green-primary'
                              : 'red-primary'
                          }
                        >
                          {currentTab === ClientPariListTab.CANCELED
                            ? t('events:status_returned')
                            : item.win
                            ? `+${Number(parie.pnl).toFixed(2)}`
                            : Number(parie.pnl).toFixed(2)}
                        </Text>
                      </StickyColumn>
                    </>
                  )}
                </ResponsiveGrid>
              ))}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default PariListTableContent;
