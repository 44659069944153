import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_NEWS_FOR_MERGE, {
  GetNewsForMergeQuery,
} from '../graphql/get-news-for-merge';

const useGetNewsForMerge = (newsId: string) => {
  const { data, error, refetch } = useQuery<GetNewsForMergeQuery>(
    GET_NEWS_FOR_MERGE,
    {
      variables: { newsId },
    }
  );

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getNewsForMerge.__typename === 'Error') {
      Toast.error(data.getNewsForMerge.error);
    }
  }, [data, error]);

  if (data?.getNewsForMerge.__typename !== 'GetNewsForMerge') {
    return { news: null, refetch };
  }

  return { news: data?.getNewsForMerge.news, refetch };
};

export default useGetNewsForMerge;
