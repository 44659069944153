import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_NEWS_LIST_FOR_MERGE, {
  GetNewsListForMergeQuery,
} from '../graphql/get-news-list-for-merge';

const useGetNewsListForMerge = (
  categoryId: string,
  sportId: string,
  lang: string,
  search?: string
) => {
  const { data, error, refetch } = useQuery<GetNewsListForMergeQuery>(
    GET_NEWS_LIST_FOR_MERGE,
    {
      variables: { categoryId, sportId, lang, search },
      fetchPolicy: 'network-only',
      skip: !categoryId || categoryId === '0' || !sportId || sportId === '0',
    }
  );

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getNewsListForMerge.__typename === 'Error') {
      Toast.error(data.getNewsListForMerge.error);
    }
  }, [data, error]);

  if (data?.getNewsListForMerge.__typename !== 'GetNewsListForMerge') {
    return { newsList: [], refetch };
  }

  return { newsListData: data?.getNewsListForMerge.news, refetch };
};

export default useGetNewsListForMerge;
