import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export type NewsForMergeType = {
  categoryId: string | null;
  id: string;
  sportId: string | null;
  isMerged: boolean;
  title: string;
};

export interface GetNewsListForMergeQuery {
  getNewsListForMerge:
    | {
        __typename: 'GetNewsListForMerge';
        news: NewsForMergeType[];
      }
    | GraphqlErrorType;
}

const GET_NEWS_LIST_FOR_MERGE = gql`
  query GetNewsListForMerge(
    $categoryId: ID!
    $sportId: String!
    $lang: String!
    $search: String
  ) {
    getNewsListForMerge(
      categoryId: $categoryId
      sportId: $sportId
      lang: $lang
      search: $search
    ) {
      __typename
      ... on GetNewsListForMerge {
        news {
          categoryId
          id
          isMerged
          sportId
          title
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_LIST_FOR_MERGE;
