import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export interface DeleteNewsMergeMutation {
  deleteNewsMerge:
    | {
        __typename: 'DeleteNewsMerge';
        deleteInfo: {
          enNewsId: string | null;
          ruNewsId: string | null;
          success: boolean;
        };
      }
    | GraphqlErrorType;
}

export interface DeleteNewsMergeMutationVars {
  newsId: string;
}

const DELETE_NEWS_MERGE = gql`
  mutation DeleteNewsMerge($newsId: ID!) {
    deleteNewsMerge(newsId: $newsId) {
      __typename
      ... on DeleteNewsMerge {
        deleteInfo {
          enNewsId
          ruNewsId
          success
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default DELETE_NEWS_MERGE;
