import { i18n } from 'next-i18next';
import React, { useEffect, useState } from 'react';

import { Button, Modal, ModalContent, Select, Toast } from 'ui';
import { Text } from 'ui/v2/typography/text';

import css from './merge-news-modal.module.scss';
import useDeleteNewsMerge from '../../hooks/use-delete-news-merge';
import useGetNewsForMerge from '../../hooks/use-get-news-for-merge';
import useGetNewsListForMerge from '../../hooks/use-get-news-list-for-merge';
import useMergeNews from '../../hooks/use-merge-news';

interface MergeNewsModalProps {
  closeModal: () => void;
  isModalOpen: boolean;
  id: string;
}

const MergeNewsModal = ({
  closeModal,
  id,
  isModalOpen,
}: MergeNewsModalProps) => {
  const { news, refetch: refetchNews } = useGetNewsForMerge(id);
  const [selectedNewsId, setSelectedNewsId] = useState<string | null>(null);
  const [newsList, setNewsList] = useState<any[]>([]);
  const mergeNews = useMergeNews();
  const { deleteNewsMerge } = useDeleteNewsMerge();

  const sportId = news?.[0]?.sportId;
  const categoryId = news?.[0]?.categoryId;
  const language = news?.[0]?.lang;

  const oppositeLanguage = language === 'ru' ? 'en' : 'ru';

  const { newsListData, refetch: refetchNewsList } = useGetNewsListForMerge(
    categoryId || '0',
    sportId || '0',
    oppositeLanguage || '',
    ''
  );

  useEffect(() => {
    if (newsListData) {
      setNewsList(newsListData);
    }
  }, [newsListData, id]);

  useEffect(() => {
    if (news?.[1]?.id) {
      setSelectedNewsId(news[1].id);
    }
  }, [news]);

  const handleSelectChange = (newNewsId: string) => {
    setSelectedNewsId(newNewsId);
  };

  const handleMerge = async () => {
    if (!selectedNewsId) {
      Toast.warning('Выберите новость для объединения');
      return;
    }

    try {
      if (i18n?.language === 'ru') {
        await mergeNews(id, selectedNewsId);
      } else {
        await mergeNews(selectedNewsId, id);
      }

      Toast.success('Новости успешно объединены');
      await refetchNews();
      await refetchNewsList();
      closeModal();
    } catch (err) {
      Toast.error('Произошла ошибка при объединении новостей');
    }
  };

  const handleDelete = async () => {
    if (!selectedNewsId) {
      Toast.warning('Выберите новость для удаления');
      return;
    }

    try {
      await deleteNewsMerge(selectedNewsId);
      await refetchNews();
      await refetchNewsList();
      closeModal();
    } catch (err) {
      Toast.error('Ошибка при удалении объединения');
    }
  };

  return (
    <Modal
      title="Объединение новостей"
      isOpen={isModalOpen}
      width={840}
      className={css.ModalRoot}
      onClose={closeModal}
    >
      <ModalContent className={css.ContentContainer}>
        <div className={css.NewsHeaderTitle}>
          <Text type={'body2'} className={css.TextNews}>
            Название новости: {news?.[0]?.title || 'Загрузка новости...'}
          </Text>
        </div>

        {news?.[0].isMerged ? (
          <Select
            value={selectedNewsId ?? ''}
            items={[
              {
                value: news?.[1]?.id || '',
                label: news?.[1]?.title || '',
              },
            ]}
            onChange={(selectedValue) => handleSelectChange(selectedValue)}
          />
        ) : (
          <Select
            value={selectedNewsId ?? ''}
            items={newsList.map((newsItem) => ({
              value: newsItem.id,
              label: newsItem.title,
            }))}
            onChange={(selectedValue) => handleSelectChange(selectedValue)}
          />
        )}

        <div className={css.ButtonControl}>
          <Button
            color="secondary"
            onClick={handleMerge}
            className={css.Button}
          >
            Объединить новости
          </Button>
          <Button
            color="secondary"
            onClick={handleDelete}
            className={css.Button}
          >
            Удалить объединение
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

export default MergeNewsModal;
