import { useMutation } from '@apollo/client';

import { Toast } from 'ui';

import DELETE_NEWS_MERGE, {
  DeleteNewsMergeMutation,
  DeleteNewsMergeMutationVars,
} from '../graphql/delete-news-merge';

interface UseDeleteNewsMergeResponse {
  deleteNewsMerge: (newsId: string) => Promise<void>;
  loading: boolean;
  error: Error | undefined;
  data: DeleteNewsMergeMutation | undefined | null;
}

const useDeleteNewsMerge = (): UseDeleteNewsMergeResponse => {
  const [deleteNewsMergeMutation, { loading, error, data }] = useMutation<
    DeleteNewsMergeMutation,
    DeleteNewsMergeMutationVars
  >(DELETE_NEWS_MERGE);

  const deleteNewsMerge = async (newsId: string) => {
    try {
      const response = await deleteNewsMergeMutation({ variables: { newsId } });

      if (response.data?.deleteNewsMerge?.__typename === 'DeleteNewsMerge') {
        const { deleteInfo } = response.data.deleteNewsMerge;

        if (deleteInfo?.success) {
          Toast.success('Объединение удалено успешно');
        } else {
          throw new Error(
            'Failed to delete the news merge or no success flag found.'
          );
        }
      } else {
        throw new Error('Error in deletion response structure.');
      }
    } catch (err: unknown) {
      const errorMessage =
        err instanceof Error ? err.message : 'Unexpected error occurred';
      Toast.error(errorMessage);
    }
  };

  return { deleteNewsMerge, loading, error, data };
};

export default useDeleteNewsMerge;
