import clsx from 'clsx';
import React from 'react';

import css from './switch.module.scss';

type SwitchItem<T> = { value: T; label: React.ReactNode };

interface SwitchProps<T> {
  value: T;
  items: SwitchItem<T>[];
  disabled?: boolean;
  onChange: (value: T) => void;
  className?: string;
  circleClassName?: string;
  fontSize?: string;
}

type SwitchPropsFC = <T>(
  props: SwitchProps<T>
) => React.ReactElement<SwitchProps<T>>;

const Switch: SwitchPropsFC = ({
  items,
  className,
  disabled,
  value,
  onChange,
  circleClassName,
  fontSize,
}) => {
  return (
    <div
      className={clsx(items.length > 1 ? css.Root : css.RootSingle, className, {
        [css.Disabled]: disabled,
      })}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={value === item.value ? css.ItemActive : css.Item}
          style={{ fontSize }}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </div>
      ))}
      <div
        className={clsx(css.Switch, circleClassName)}
        style={value === items[0].value ? { left: 2 } : { right: 2 }}
      />
    </div>
  );
};

export default Switch;
