import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { errorNotification } from 'application/notification';
import { Toast } from 'ui';

import GET_NEWS_REVIEWING_STATUSES_LIST, {
  GetNewsReviewingStatusesListQuery,
} from '../graphql/get-news-reviewing-statuses-list';

const useGetNewsReviewingStatusesList = () => {
  const { data, error } = useQuery<GetNewsReviewingStatusesListQuery>(
    GET_NEWS_REVIEWING_STATUSES_LIST
  );

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getNewsReviewingStatusesList.__typename === 'Error') {
      errorNotification(data.getNewsReviewingStatusesList.error);
    }
  }, [data, error]);

  if (
    data?.getNewsReviewingStatusesList.__typename !==
    'GetNewsReviewingStatusesList'
  )
    return null;

  return data?.getNewsReviewingStatusesList.statuses;
};

export default useGetNewsReviewingStatusesList;
