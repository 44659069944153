import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';

import GET_MY_CLIENT from 'application/graphql/get-my-client';
import { Toast } from 'ui';

import TransferMoneyDialog, {
  TransferMoneyDialogErrors,
  TransferMoneyDialogValues,
} from './transfer-money-dialog';
import GET_MY_CLIENT_TRANSACTIONS from '../../graphql/get-my-client-transactions';
import TRANSFER_MONEY, {
  TransferMoneyMutation,
  TransferMoneyMutationVars,
} from '../../graphql/transfer-money';

const TransferMoneyDialogConnected: React.FC<{
  from: 'vusd' | 'mvusd';
  amount?: string;
  isOpen?: boolean;
  onClose: () => void;
}> = ({ from, amount = '', isOpen = true, onClose }) => {
  const { t } = useTranslation(['common', 'bets']);
  const [processing, setProcessing] = useState(false);
  const [formValues, setFormValues] = useState<TransferMoneyDialogValues>({
    amount,
  });
  const [formErrors, setFormErrors] = useState<TransferMoneyDialogErrors>({});

  const [transferMoney] = useMutation<
    TransferMoneyMutation,
    TransferMoneyMutationVars
  >(TRANSFER_MONEY, {
    refetchQueries: [GET_MY_CLIENT, GET_MY_CLIENT_TRANSACTIONS],
  });

  return (
    <TransferMoneyDialog
      processing={processing}
      isOpen={isOpen}
      onClose={onClose}
      values={formValues}
      errors={formErrors}
      onChange={(values) => {
        setFormValues({
          ...formValues,
          ...values,
        });
        setFormErrors({
          ...formErrors,
          ...Object.keys(values).reduce(
            (prev, cur) => ({ ...prev, [cur]: undefined }),
            {}
          ),
        });
      }}
      onSubmit={async () => {
        try {
          setProcessing(true);

          const { data, errors } = await transferMoney({
            variables: {
              input: {
                from,
                to: from === 'vusd' ? 'mvusd' : 'vusd',
                amount: formValues.amount,
              },
            },
          });

          if (errors) {
            Toast.debugError(errors.map((item) => item.message).join(', '));
            return;
          }

          if (!data) {
            Toast.error(t('common:errors:undefined_error'));
            return;
          }

          if (data?.transferMoney.__typename === 'Error') {
            if (data.transferMoney.warnings) {
              return setFormErrors(
                data.transferMoney.warnings.reduce(
                  (prev, cur) => ({
                    ...prev,
                    [cur.key]: cur.warning,
                  }),
                  {}
                )
              );
            }
            Toast.error(data.transferMoney.error);
            return;
          }

          Toast.info(t('bets:money_transferred'));
          onClose();
        } catch (e) {
          console.log(e);
        } finally {
          setProcessing(false);
        }
      }}
    />
  );
};

export default TransferMoneyDialogConnected;
