import { useMutation } from '@apollo/client';
import { useTranslation } from 'next-i18next';

import { Toast } from 'ui';

import { verifyApolloMutationResponse } from '../../../application/graphql/utils';
import MERGE_NEWS, {
  MergeNewsMutation,
  MergeNewsMutationVars,
} from '../graphql/merge-news';

const useMergeNews = () => {
  const { t } = useTranslation(['news']);
  const [mergeNewsMutation] = useMutation<
    MergeNewsMutation,
    MergeNewsMutationVars
  >(MERGE_NEWS);

  return async (ruNewsId: string, enNewsId: string) => {
    try {
      const { data, errors } = await mergeNewsMutation({
        variables: { ruNewsId, enNewsId },
      });

      const isMergeNewsSuccess = verifyApolloMutationResponse(data, errors);
      if (!isMergeNewsSuccess) return;

      if (data?.mergeNews.__typename === 'Error') {
        Toast.error(data.mergeNews.error);
        return;
      }

      if (data?.mergeNews.__typename === 'MergeNewsInfo') {
        const { mergeInfo } = data.mergeNews;
        if (mergeInfo.success) {
          Toast.success(t('news:merge_success'));
        } else {
          Toast.warning(t('news:merge_partial_warning'));
        }
        return mergeInfo;
      }
    } catch (e: unknown) {
      Toast.error(t('common:errors:something_went_wrong'));
    }
  };
};

export default useMergeNews;
