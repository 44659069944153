import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { errorNotification } from 'application/notification';
import { getCorrectSortDirection } from 'application/utils/getCorrectSortDirection';
import { isGraphqlError } from 'features/main/components/graphql-error-guard/graphql-error-guard';
import { Toast } from 'ui';
import { SortDirection } from 'ui/sorting-button/sorting-button.types';

import GET_STATS_EVENTS, {
  GetStatsByEventsQuery,
  GetStatsByEventsQueryVars,
  PAGE_LIMIT_LIVE,
} from '../graphql/get-user-statistic-live-orders';

export const useGetStatisticLive = () => {
  const [direction, setDirection] = useState<SortDirection>(SortDirection.NONE);

  const { data, loading, fetchMore, error } = useQuery<
    GetStatsByEventsQuery,
    GetStatsByEventsQueryVars
  >(GET_STATS_EVENTS, {
    variables: {
      nav: { limit: PAGE_LIMIT_LIVE, offset: 0 },
      sort: {
        eventStartDate: getCorrectSortDirection(direction),
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const onGetNextPage = (limit: number) => {
    if (data?.getStatsByEvents.__typename !== 'GetStatsByEvents') return;
    fetchMore({
      variables: {
        nav: {
          limit: PAGE_LIMIT_LIVE,
          offset: data.getStatsByEvents.nav.offset + limit,
        },
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        if (isGraphqlError(previousResult.getStatsByEvents))
          return previousResult;
        if (isGraphqlError(fetchMoreResult.getStatsByEvents))
          return previousResult;

        fetchMoreResult.getStatsByEvents.events = [
          ...previousResult.getStatsByEvents.events,
          ...fetchMoreResult.getStatsByEvents.events,
        ];

        return fetchMoreResult;
      },
    });
  };

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getStatsByEvents.__typename === 'Error') {
      errorNotification(data.getStatsByEvents.error);
    }
  }, [data, error]);

  return { data, loading, onGetNextPage, sort: { direction, setDirection } };
};
