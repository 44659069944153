import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export interface MergeNewsMutation {
  mergeNews:
    | {
        __typename: 'MergeNewsInfo';
        mergeInfo: {
          enNewsId: string;
          ruNewsId: string;
          success: boolean;
        };
      }
    | GraphqlErrorType;
}

export interface MergeNewsMutationVars {
  ruNewsId: string;
  enNewsId: string;
}

const MERGE_NEWS = gql`
  mutation MergeNews($ruNewsId: ID!, $enNewsId: ID!) {
    mergeNews(ruNewsId: $ruNewsId, enNewsId: $enNewsId) {
      ... on MergeNews {
        mergeInfo {
          enNewsId
          ruNewsId
          success
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default MERGE_NEWS;
