import { gql } from '@apollo/client';

import { GraphqlErrorType } from '../../../application/graphql/graphql-types';

export type NewsForMergeType = {
  categoryId: string | null;
  id: string;
  isMerged: boolean;
  lang: string;
  sportId: string | null;
  title: string;
};

export interface GetNewsForMergeQuery {
  getNewsForMerge:
    | {
        __typename: 'GetNewsForMerge';
        news: NewsForMergeType[];
      }
    | GraphqlErrorType;
}

const GET_NEWS_FOR_MERGE = gql`
  query GetNewsForMerge($newsId: ID!) {
    getNewsForMerge(newsId: $newsId) {
      __typename
      ... on GetNewsForMerge {
        news {
          isMerged
          id
          categoryId
          lang
          sportId
          title
        }
      }
      ... on Error {
        error
      }
    }
  }
`;

export default GET_NEWS_FOR_MERGE;
