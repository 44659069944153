import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_NEWS_REJECTED_REASON_LIST, {
  GetNewsRejectedReasonListQuery,
} from '../graphql/get-news-rejected-reason-list';

function useGetNewsRejectedReasonsList() {
  const { data, error } = useQuery<GetNewsRejectedReasonListQuery>(
    GET_NEWS_REJECTED_REASON_LIST
  );

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
      return;
    }

    if (data?.getNewsRejectedReasonList.__typename === 'Error') {
      Toast.error(data.getNewsRejectedReasonList.__typename);
    }
  }, [data, error]);

  if (
    data?.getNewsRejectedReasonList.__typename !== 'GetNewsRejectedReasonList'
  ) {
    return null;
  }

  return data.getNewsRejectedReasonList.reasons;
}

export default useGetNewsRejectedReasonsList;
