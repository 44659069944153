import { useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { Toast } from 'ui';

import GET_EVENT_SPORTS_LIST, {
  GetEventSportsListQuery,
} from '../graphql/get-event-sports-list';

const useGetEventSportsList = () => {
  const { data, error } = useQuery<GetEventSportsListQuery>(
    GET_EVENT_SPORTS_LIST
  );

  useEffect(() => {
    if (error) {
      Toast.debugError(error.message);
    }

    if (data?.getEventSportsList.__typename === 'Error') {
      Toast.error(data.getEventSportsList.error);
    }
  }, [data, error]);

  if (data?.getEventSportsList.__typename !== 'GetEventSportsList') {
    return null;
  }

  return data?.getEventSportsList.sports;
};

export default useGetEventSportsList;
